import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import { userIs, capitalizeFirst } from '/lib/helpers';
import { useUserState } from '/store/userStore';
import { UserRole } from '/types/userTypes';

export default function Logo() {
    const user = useUserState((state) => state.user);

    const isSeller = user && userIs(user, [UserRole.SALES_PARTNER_SELLER]);

    const [showEnvBadge, setShowEnvBadge] = useState(false);

    useEffect(() => {
        setShowEnvBadge(user && userIs(user, ['GLOBAL_ADMIN']));
    });

    const env = process.env.NEXT_PUBLIC_ENVIRONMENT;

    return (
        <Wrapper>
            <Link
                prefetch={false}
                href={isSeller ? '/selger' : '/'}
                id="home-link"
            >
                <svg
                    width="98"
                    height="26"
                    className="logo"
                    viewBox="0 0 98 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        className="logo-text"
                        clipRule="evenodd"
                        d="M84.4305 20.5363H88.0862V12.9297C88.0862 10.903 89.3305 9.58181 91.2289 9.58181C93.076 9.58181 94.3203 10.903 94.3203 12.9297V20.5363H97.9889V12.4808C97.9889 8.50432 95.7569 6.06715 92.1396 6.06715C90.3951 6.06715 88.9713 6.92657 88.0862 8.4017V6.375H84.4305V20.5363ZM57.2496 20.7415C57.7755 20.7415 58.5708 20.6645 59.0967 20.5363V17.1627C58.7632 17.2397 58.3912 17.2653 58.0321 17.2653C56.5569 17.2653 55.4666 16.5855 55.4666 14.9308V9.85118H59.0967V6.375H55.4666V2.62945H51.8109V6.375H49.1556V9.85118H51.8109V14.9949C51.8365 18.6891 54.0043 20.7415 57.2496 20.7415ZM40.959 17.3166C38.8297 17.3166 37.2135 15.6491 37.2135 13.4685C37.2135 11.2365 38.8297 9.59463 40.959 9.59463C43.0883 9.59463 44.7559 11.2365 44.7559 13.4685C44.7559 15.6491 43.0883 17.3166 40.959 17.3166ZM40.959 20.8185C45.115 20.8185 48.3347 17.6758 48.3347 13.4685C48.3347 9.26112 45.115 6.06715 40.959 6.06715C36.803 6.06715 33.6347 9.26112 33.6347 13.4685C33.6347 17.6758 36.803 20.8185 40.959 20.8185ZM24.5915 17.3423C22.3595 17.3423 20.6663 15.6491 20.6663 13.4428C20.6663 11.2365 22.3595 9.56898 24.5915 9.56898C26.7721 9.56898 28.491 11.2878 28.491 13.4428C28.491 15.6234 26.7593 17.3423 24.5915 17.3423ZM17.0106 25.3593H20.6663V18.2658C21.654 19.9077 23.2831 20.8313 25.3996 20.8313C29.1195 20.8313 32.0698 17.5347 32.0698 13.4556C32.0698 9.40222 29.1323 6.07997 25.3996 6.07997C23.2703 6.07997 21.654 7.01636 20.6663 8.67107V6.375H17.0106V25.3593ZM7.63389 20.8185C11.8412 20.8185 14.9582 18.2017 14.9582 14.687C14.9582 6.90092 4.00378 9.62029 4.00378 5.83626C4.00378 4.4894 5.19672 3.62997 7.01818 3.62997C9.04488 3.62997 10.3148 4.87421 10.3661 6.56741H14.3169C14.3169 3.0784 11.9951 0.0126953 7.17211 0.0126953C3.19567 0.0126953 0.258234 2.47552 0.258234 5.86191C0.258234 13.2632 11.1614 10.6208 11.1614 14.687C11.1614 16.2135 9.68624 17.2012 7.63389 17.2012C5.3763 17.2012 3.81138 15.88 3.81138 13.9046H0.0273438C0.0273438 17.9323 3.22132 20.8185 7.63389 20.8185Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M82.1204 10.3771C82.1204 16.1878 77.3999 20.9724 71.602 20.9724C65.7913 20.9724 61.1094 16.3033 61.1094 10.5054C61.1094 4.70746 65.8041 0.0126953 71.602 0.0126953C73.6287 0.0126953 75.1039 0.423166 76.5149 1.23128L73.8853 4.09175C73.2183 3.66845 72.4102 3.48887 71.602 3.48887C67.7282 3.48887 64.5855 6.63154 64.5855 10.5054C64.5855 14.3792 67.7282 17.4962 71.602 17.4962C75.4759 17.4962 78.6442 14.3792 78.6442 10.5054C78.6442 9.49201 78.4261 8.47866 78.0926 7.96557L71.5251 14.5588L67.1125 10.4027L69.3958 8.06819L71.5251 10.1719L78.8751 2.87317C80.8248 4.7716 82.1204 7.33704 82.1204 10.3771Z"
                        fill="#FF6400"
                    />
                </svg>
            </Link>
            {showEnvBadge && (
                <span className={`env-${env}`}>{capitalizeFirst(env)}</span>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    > span {
        position: absolute;
        left: 0;
        bottom: -30px;
        color: #fff;
        font-size: 14px;
        line-height: 1;
        border-radius: 12px;
        display: flex;
        padding: 4px 12px 6px;
        z-index: 1;
        &.env-local {
            background: #4caf50;
        }
        &.env-staging {
            background: #ff9800;
        }
        &.env-production {
            background: #f44336;
        }
    }
    #home-link {
        display: flex;
        position: relative;
        img {
            min-width: 98px;
            min-height: 26px;
        }
    }
`;
