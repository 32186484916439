import styled from 'styled-components';

const Divider = ({ locked = false, label = '', ...rest }) => {
    return <Wrapper {...rest} locked={locked} label={label} />;
};

export default Divider;

const Wrapper = styled.div`
    display: block;
    width: 100%;
    height: 3px;
    background: rgba(76, 71, 108, 0.08);
    position: relative;
    ${(props) =>
        props.locked &&
        `
        margin-top:48px!important;
        margin-bottom:48px!important;
    `}

    ${(props) =>
        props.label &&
        `
        &:after {
            text-align: center;
            content: '${props.label}';
            position: absolute;
            background: #ECEDF0;
            border-radius: 12px;
            font-size: 17px;
            font-family: "gellix-semi";
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding: 6px 16px 8px;
            line-height: 1;
            white-space: nowrap;
        }
    `}
`;
