import { useEffect } from 'react';
import Router from 'next/router';
import RichTextType from '../types/frontRichTextType';
import { DOMElement } from 'slate-react/dist/utils/dom';
import { AnyUserType } from '/types/userTypes';

export const isObject = (v: any) =>
    v &&
    v.constructor !== Array &&
    v === Object(v) &&
    typeof v !== 'function' &&
    v instanceof Promise === false &&
    v instanceof Date === false;

export const cookie = {
    set: (cname: string, cvalue: string, exdays?: number) => {
        if (typeof window === 'undefined') return false;
        const d = new Date();
        d.setTime(d.getTime() + (exdays || 1) * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    get: (cname: string) => {
        if (typeof window === 'undefined') return false;
        const name = cname + '=';
        return (
            document.cookie
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
    getFromString: (cname: string, string: string) => {
        if (!string) return '';
        const name = cname + '=';
        return (
            string
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
};

export const isUrl = (text: string) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '(?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])?\\.)+[a-z]{2,}' + // validate domain
            '(?:\\:\\d+)?' + // validate port
            '(?:\\/[^\\s]*)?$', // validate path
        'i'
    );
    return urlPattern.test(text);
};

export const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = toInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const truncate = (fullStr: string, strLen: number) => {
    if (typeof fullStr !== 'string') return '';

    if (fullStr.length <= strLen) return fullStr;

    const separator = '...';
    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return (
        fullStr.substring(0, frontChars) +
        separator +
        fullStr.substring(fullStr.length - backChars)
    );
};

export const useOnClickOutside = (
    ref: React.RefObject<HTMLElement>,
    callback: () => void
) => {
    const handleClick = (e: MouseEvent) => {
        const target = e.target as Node;
        if (ref.current && !ref.current.contains(target)) callback();
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};

export const goTo = (href: string, asNewTab: boolean) => {
    if (!href || typeof window === 'undefined') return;
    if (asNewTab) {
        const newWindow = window.open(window.location.origin + href, '_blank');
        if (newWindow) newWindow.focus();
    }
    Router.push(href).then(() => window.scrollTo(0, 0));
    return null;
};

export const toInt = (val: any) => Number(val) | 0;

export const trim2 = (val: number) => toInt(val * 100) / 100;

export const trim1 = (val: number) => toInt(val * 10) / 10;

export const percentOf = (x: number, y: number) =>
    Math.ceil((x / (y || 1)) * 100);

export const percentOfPrecise = (x: number, y: number) => (x / (y || 1)) * 100;

export const percentToNum = (per: number, max: number) =>
    Math.ceil((max / 100) * per);

export const percentToNumPrecise = (per: number, max: number) =>
    (max / 100) * per;

export const getPixelRatio = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return 1; // Fallback to 1 if the 2D context is not available
    }

    const dpr = window.devicePixelRatio || 1;
    const bsr =
        (ctx as any).webkitBackingStorePixelRatio ||
        (ctx as any).mozBackingStorePixelRatio ||
        (ctx as any).msBackingStorePixelRatio ||
        (ctx as any).oBackingStorePixelRatio ||
        (ctx as any).backingStorePixelRatio ||
        1;

    return dpr / bsr;
};

export const clone = (object: { [key: string]: any }) =>
    JSON.parse(JSON.stringify(object));

export const changeArrayItemPosition = (
    array: any[],
    currentIndex: number,
    newIndex: number
) => {
    let _array = [...array];
    const a = _array[currentIndex];
    const b = _array[newIndex];
    if (!a || !b) return array;
    _array[currentIndex] = b;
    _array[newIndex] = a;
    return _array;
};

export const classes = (_names: any[]) => {
    return {
        className: (Array.isArray(_names) ? _names : [_names])
            .filter((n) => n)
            .join(' '),
    };
};

export const isInView = (el?: DOMElement, mustBeCompletelyInView = true) => {
    if (!el || typeof window === 'undefined') return false;

    const bcr = el.getBoundingClientRect();
    const elTop = bcr.top;
    const elBottom = bcr.bottom;

    return mustBeCompletelyInView
        ? elTop >= 0 && elBottom <= window.innerHeight
        : elTop < window.innerHeight && elBottom >= 0;
};

export const copyToClipboard = (text: string, onSuccess: () => void) => {
    if (!navigator.clipboard) {
        let textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            let successful = document.execCommand('copy');
            if (successful) onSuccess();
        } catch (err) {
            console.warn('Unable to copy text with fallback action', err);
        }

        document.body.removeChild(textArea);
        return;
    }

    navigator.clipboard.writeText(text).then(
        function () {
            onSuccess();
        },
        function (err) {
            console.warn('Unable to copy text', err);
        }
    );
};

export const pad2 = (number: number) => {
    let str = '' + number;
    while (str.length < 2) {
        str = '0' + str;
    }
    return str;
};

export const formatDateTime = (dateTime: string) => {
    const dt = new Date(dateTime);
    const monthNames = [
        'Jan.',
        'Feb.',
        'Mar.',
        'Apr.',
        'May',
        'Jun.',
        'Jul.',
        'Aug.',
        'Sep.',
        'Oct.',
        'Nov.',
        'Dec.',
    ];
    return (
        pad2(dt.getDate()) +
        ' ' +
        monthNames[dt.getMonth() + 1] +
        //+ '.' + dt.getFullYear()
        ' ' +
        pad2(dt.getHours()) +
        ':' +
        pad2(dt.getMinutes())
    );
};

export const richContentIsEmpty = (content?: RichTextType | null) => {
    if (!content?.length) return true;

    return (
        content &&
        content.length === 1 &&
        content[0]._type === 'block' &&
        content[0].children.length === 1 &&
        content[0].children[0]._type === 'span' &&
        content[0].children[0].text === ''
    );
};

export const convertPlainTextContentToRich = (
    content: RichTextType | string | undefined | null
): RichTextType => {
    if (!content) return [];
    if (typeof content === 'string') {
        return [
            {
                children: [
                    {
                        text: content,
                        _key: (Math.random() + 1).toString(36).substring(5),
                        _type: 'span',
                        marks: [],
                    },
                ],
                markDefs: [],
                style: 'normal',
                _type: 'block',
                _key: (Math.random() + 1).toString(36).substring(5),
            },
        ];
    }
    return content;
};

export const getUserInitials = (user: AnyUserType): string => {
    return user.firstName?.[0] + user.lastName?.[0] || '';
};

export const ensureArray = (val: any) => (Array.isArray(val) ? val : []);

export const insertAtIndex = <T>(
    arr: T[],
    index: number,
    newItem: T | T[]
): T[] => [
    ...arr.slice(0, index),
    ...(Array.isArray(newItem) ? newItem : [newItem]),
    ...arr.slice(index),
];

export const toSnake = (str: string) =>
    str
        .replace(/[\s-]+/g, '_')
        .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
        .replace(/^_/, '');

export const camelToSnake = (str: string) =>
    str
        .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
        .replace(/([a-z0-9])([A-Z])/, '$1_$2')
        .toLowerCase();

export const objectToQuery = (
    obj: Record<
        string,
        string | string[] | number | number[] | boolean | boolean[]
    >
) =>
    Object.keys(obj || {})
        .filter((key) => obj[key])
        .map((key) => {
            const value = obj[key];

            if (!Array.isArray(value)) {
                return `${camelToSnake(key)}=${value}`;
            }

            return (
                value
                    //@ts-ignore
                    .filter((val) => !!val)
                    //@ts-ignore
                    .map((val) => `${camelToSnake(key)}[]=${val}`)
                    .join('&')
            );
        })
        .filter((val) => val)
        .join('&');

interface ModuleType<DataType> {
    data: DataType;
}

export function createDataValueGetter<DataType>(
    module: ModuleType<DataType>,
    moduleConfig: ModuleType<DataType>
) {
    return function <T extends keyof DataType>(key: T): DataType[T] {
        return module.data[key] === undefined
            ? moduleConfig.data[key]
            : module.data[key];
    };
}
