import { css } from 'styled-components';
import { ButtonStyleProps } from './Button';

const buttonSizes = {
    large: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        height: 58px;
        padding: 0 24px 3px;
        line-height: 1;
        font-size: 20px;
    `,
    medium: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        height: 54px;
        font-size: 17px;
        line-height: 24px;
        padding: 0 16px 2px;

        .plus-icon {
            position: relative;
            top: 4px;
        }
    `,
    small: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        height: 34px;
        min-height: 34px;
        font-size: 17px;
        line-height: 22px;
        padding: 0 10px 1px;

        .button-icon {
            //top: 2px;
            //transform: scale(0.9);
        }
    `,
};

export default buttonSizes;
