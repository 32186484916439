import { stripNilProps } from '/lib/helpers';
import Category from '/front/types/frontCategoryType';
import api from '/api';
import { Error } from '/front/types/frontApiType';
import ImageType from '/front/types/frontImageType';
import snakecaseKeys from 'snakecase-keys';

interface SpotonCategory {
    id: string | number;
    name: string;
    icon: ImageType | null;
    order: number;
    pageId: string | number;
    pagePath: string;
}

const toFrontCategoryFormat =
    (type: string) =>
    (category: SpotonCategory): Category => ({
        _id: category.id,
        _type: type,
        title: category.name,
        image: category.icon,
        order: category.order,
        pageId: category.pageId,
        pagePath: category.pagePath,
    });

const toSpotonCategoryFormat = (category: Category): Partial<SpotonCategory> =>
    stripNilProps({
        id: category._id || undefined,
        name: category.title,
        icon: category.image,
        order: category.order,
        pageId: category.pageId,
        pagePath: category.pagePath,
    });

const frontCategoryApi = {
    get: async ({ type }: { type: string }): Promise<Category[] | Error> => {
        const resp = await api.get('job-category');

        if ('error' in resp) return { error: resp.error };

        return resp.map(toFrontCategoryFormat(type));
    },
    post: async (category: Category): Promise<Category | Error> => {
        const resp = await api.post(
            'job-category',
            snakecaseKeys(toSpotonCategoryFormat(category))
        );

        if ('error' in resp) return { error: resp.error };
        return toFrontCategoryFormat('job-category')(resp as SpotonCategory);
    },
    patch: async (category: Category): Promise<Category | Error> => {
        const resp = await api.patch(
            `job-category/${category._id}`,
            snakecaseKeys(toSpotonCategoryFormat(category))
        );

        if ('error' in resp) return { error: resp.error };
        return toFrontCategoryFormat('job-category')(resp as SpotonCategory);
    },
    patchOrder: async (orderObject: {
        [key: string]: number;
    }): Promise<{} | Error> => {
        const resp = await api.post('job-category/order', orderObject);

        if ('error' in resp) return { error: resp.error };
        return {};
    },
    delete: async (category: Category): Promise<{} | Error> => {
        const resp = await api.delete(`job-category/${category._id}`);

        if ('error' in resp) return { error: resp.error };
        return {};
    },
};

export default frontCategoryApi;
