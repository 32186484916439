import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '/css';
import Button from '/components/ui/buttons/Button';
import PostCodeCheckerModal from '../features/post-code-checker-modal/PostCodeCheckerModal';
import PointerIocn from '/components/icons/PointerIcon';
import { useRouter } from 'next/router';
import usePostCodeState from '/hooks/usePostCodeState';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    withPlaceName?: boolean;
}

export default function PostCodeStatusButton({
    withPlaceName,
    ...rest
}: Props) {
    const { postCode, postCodePlace } = usePostCodeState();

    const router = useRouter();

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    return (
        <>
            {postCode && (
                <CodeButton
                    size="small"
                    variant="tertiary"
                    className="mb-12"
                    onClick={() => {
                        const codeInput = document.querySelector(
                            '#post-code-input input'
                        );
                        if (codeInput) {
                            (codeInput as HTMLInputElement).focus();
                        } else {
                            setModalVisible(true);
                        }
                    }}
                    icon={<PointerIocn />}
                    iconPosition="left"
                    {...rest}
                >
                    {postCode} {withPlaceName && postCodePlace}
                </CodeButton>
            )}
            {modalVisible && (
                <PostCodeCheckerModal
                    closeRequestCallback={() => setModalVisible(false)}
                    cartContext={router.pathname === '/order/cart'}
                />
            )}
        </>
    );
}

const CodeButton = styled(Button)`
    border-color: ${colors.purpleLighter5};
    background-color: ${colors.purpleLighter6}!important;
    color: ${colors.purple}!important;
`;
