import { useEffect, useRef } from 'react';

const useOnClickOutside = <T extends HTMLDivElement>(
    callback: () => void,
    disabled: boolean = false
) => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                event.target instanceof HTMLElement &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                callback();
            }
        };

        if (!disabled) {
            const isTouch = 'ontouchstart' in (document?.documentElement || '');
            const event = isTouch ? 'ontouchstart' : 'mousedown';

            document.addEventListener(event, handleClickOutside);
            return () =>
                document.removeEventListener(event, handleClickOutside);
        }
    }, [ref, callback, disabled]);

    return ref;
};

export default useOnClickOutside;
