import { objectToQuery } from '/lib/helpers';
import { PageType } from '/front/types/frontPageType';
import snakecaseKeys from 'snakecase-keys';
import ModuleType, {
    SpotonSimpleModuleType,
} from '/front/types/frontModuleType';
import { PaginatedResponse, Error } from '/types/apiResponseTypes';
import ImageType from '/front/types/frontImageType';

import api from './api';

export interface SpotonPage {
    uuid: string;
    type: PageType;
    id: number;
    parentId: number | null;
    draftId?: number | null;
    published: boolean;
    title: string;
    slug: string;
    path: string;
    data: {
        seo: {
            title: string;
            description: string;
            keywords: string[];
            image: ImageType | null;
        };
        background: string;
        modules: SpotonSimpleModuleType[];
    };
    createdAt: string;
    updatedAt: string;
}

export interface SomeOfSpotonPage extends Partial<SpotonPage> {}

export type PageTypeParam = PageType | 'job';

export interface PageParams {
    uuid?: string;
    slug?: string;
    path?: string;
    query?: string;
    type?: PageTypeParam | PageTypeParam[];
    page?: number;
    perPage?: number;
    published?: boolean;
}

export interface LinkSearchResult {
    id: number;
    parentId: number;
    path: string;
    similarity: number;
    slug: string;
    title: string;
    type: PageType | 'job';
    uuid: string;
}

const pageApi = {
    index: (
        params: PageParams
    ): Promise<PaginatedResponse<SpotonPage> | Error> =>
        api.get(`page?${objectToQuery(params)}`),

    getById: (id: number): Promise<SpotonPage | Error> =>
        api.getSecure(`page/${id}`),

    create: (data: SpotonPage): Promise<SpotonPage | Error> =>
        api.post(`page`, snakecaseKeys(data, { deep: false })),

    update: (
        id: number | string,
        data: SomeOfSpotonPage
    ): Promise<SpotonPage | Error> =>
        api.patch(`page/${id}`, snakecaseKeys(data, { deep: false })),

    delete: (id: number | string): Promise<{} | Error> =>
        api.delete(`page/${id}`),

    linkSearch: (
        params: PageParams
    ): Promise<PaginatedResponse<LinkSearchResult> | Error> =>
        api.getSecure(`cms/link-search?${objectToQuery(params)}`),
};

export default pageApi;
