import styled, { css } from 'styled-components';
import { MenuItem as MenuItemType } from './Menu.types';
import { colors } from '/css';
import Link from 'next/link';
import useDropdown from '/hooks/useDropdown';
import DropdownContent, { DropdownItem } from '../Dropdown/DropdownContent';
import ChevronIcon from '/components/icons/ChevronIcon';

interface Props {
    item: MenuItemType;
    active: boolean;
}

const DropdownMenuItem = ({ item, active, ...props }: Props) => {
    const { dropdownRef, dropdownIsOpen, toggleDropdown } = useDropdown();

    return (
        <Component ref={dropdownRef as any} active={active}>
            <div onClick={toggleDropdown} className="dropdown-button">
                <span>{item.title}</span>
                <ChevronIcon
                    direction={dropdownIsOpen ? 'up' : 'down'}
                    color={active ? colors.purple : colors.black}
                />
            </div>
            <DropdownContent
                visible={dropdownIsOpen}
                hideWithStyle
                className="dropdown-content"
            >
                {item.children?.map((child, i) => (
                    <DropdownItem key={child.href}>
                        <MenuItem item={child} active={active} />
                    </DropdownItem>
                ))}
            </DropdownContent>
        </Component>
    );
};

const MenuItem = ({ item, active, ...props }: Props) => {
    if (item.children) {
        return <DropdownMenuItem item={item} active={active} {...props} />;
    }

    return (
        <Component active={active} className={item.class}>
            <Link prefetch={false} href={item.href}>
                {item.title}
            </Link>
        </Component>
    );
};

const Dropdown = styled.div``;

const Component = styled.div<{ active: boolean }>`
    margin-left: 24px;
    position: relative;
    > a,
    > .dropdown-button {
        font-family: 'gellix-semi';
        font-size: 17px;
        line-height: 22px;
        transition: 0.15s ease-in-out;
        white-space: nowrap;
        cursor: pointer;
        @media (pointer: fine) {
            &:hover,
            &:active {
                color: ${colors.purple};
            }

            &:focus {
                //color: ${colors.orange};
                outline: none;
            }
        }

        ${(p) =>
            p.active &&
            css`
                color: ${colors.purple};
            `};
    }

    .dropdown-button {
        position: relative;
        padding-right: 24px;

        svg {
            position: absolute;
        }
    }
`;

export default MenuItem;
