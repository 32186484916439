import { useState, useEffect } from 'react';
import styled from 'styled-components';

import Label from '/components/ui/typography/Label';
import Input from '/components/ui/form/Input';
import postCodeApi from '/api/postCode';
import { JobTemplate, JobTemplateType } from '/types/jobTemplateTypes';
import Alert from '/components/ui/alert/Alert';
import usePostCodeState, { PostCodeState } from '/hooks/usePostCodeState';
import PointerIocn from '/components/icons/PointerIcon';

const jobTypeName = {
    [JobTemplateType.ELECTRICIAN]: 'elektrikerjobber',
    [JobTemplateType.HEAT_PUMP]: 'varmepumper',
    [JobTemplateType.SOLAR]: 'solceller',
};

interface Props {
    jobTypeContext?: JobTemplate['typeKey'];
    cartContext?: boolean;
    onInputChange?: (value: string) => void;
    postCodeStateOverride?: PostCodeState;
}

export default function PostCodeCheckerInput({
    jobTypeContext,
    cartContext,
    onInputChange,
    postCodeStateOverride,
}: Props) {
    const [inputValue, setInputValue] = useState<string>('');
    const [notAPostCode, setNotAPostCode] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<React.ReactNode>(null);

    const {
        postCodeStateHydrated,
        postCode,
        setPostCode,
        postCodePlace,
        setPostCodePlace,
        setPostCodeValid,
        postCodeSupportedJobTypes,
        setPostCodeSupportedJobTypes,
        cartHasJobsUnsupportedInPostCodeLocation,
    } = usePostCodeState(postCodeStateOverride);

    useEffect(() => {
        if (inputValue.length === 4 && inputValue !== postCode) {
            checkPostCode(inputValue);
        }

        if (!postCode) return setAlertMessage(null);

        if (notAPostCode) {
            return setAlertMessage(
                <Alert type="error" className="mt-16-all">
                    Postkoden {postCode}, finnes ikke.
                </Alert>
            );
        }

        if (jobTypeContext) {
            if (postCodeSupportedJobTypes.includes(jobTypeContext)) {
                return setAlertMessage(
                    <Alert type="success" className="mt-16-all">
                        Vi leverer {jobTypeName[jobTypeContext]} i ditt område
                        🥳
                    </Alert>
                );
            }
            return setAlertMessage(
                <Alert type="info" className="mt-16-all">
                    Beklager, vi har ikke lansert {jobTypeName[jobTypeContext]}{' '}
                    i ditt område enda. Få varsel på e-post når vi utvider.
                </Alert>
            );
        }

        if (postCodeSupportedJobTypes.length === 0) {
            // Supporting no jobs
            return setAlertMessage(
                <Alert type="info" className="mt-16-all">
                    Beklager, vi har ikke lansert i ditt område enda. Få varsel
                    på e-post når vi utvider.
                </Alert>
            );
        }

        if (cartContext && cartHasJobsUnsupportedInPostCodeLocation) {
            // supporting some jobs in cart context
            return setAlertMessage(
                <Alert type="info" className="mt-16-all">
                    En eller flere jobber i handlekurven er ikke tilgjengelig i
                    ditt område
                </Alert>
            );
        }

        // We support jobs but not electrician jobs (some jobs)
        return setAlertMessage(
            <Alert type="success" className="mt-16-all">
                Vi leverer i ditt område {postCode}, {postCodePlace} 🥳
            </Alert>
        );
    }, [postCodeSupportedJobTypes, inputValue, notAPostCode]);

    useEffect(() => {
        if (postCode) {
            setInputValue(postCode);
        }
    }, [postCodeStateHydrated]);

    useEffect(() => {
        if (typeof onInputChange === 'function') {
            onInputChange(inputValue);
        }
    }, [inputValue]);

    const checkPostCode = async (postCode: string) => {
        const response = await postCodeApi.checkPostCode(postCode);

        setPostCode(postCode);
        setNotAPostCode(false);

        if ('error' in response) {
            setPostCodeValid(false);
            setPostCodeSupportedJobTypes([]);
            setPostCodePlace('');

            if (response.error === 'Postnummeret er ugyldig') {
                setNotAPostCode(true);
            }
        } else {
            setPostCodeValid(true);
            setPostCodeSupportedJobTypes(response.data.jobTypes || []);
            setPostCodePlace(response.data.place);
        }
    };

    return (
        <Wrapper>
            <Label className="mt-24">Ditt postnummer</Label>
            <Input
                autoFocus={true}
                className="mt-8"
                onChange={setInputValue}
                id="post-code-input"
                icon={<PointerIocn />}
                regex="^[0-9]{0,4}$"
                clearable={true}
                value={inputValue}
            />
            {alertMessage}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    #post-code-input svg {
        width: 18px;
        height: 18px;
        path {
            fill: #1f1a22;
        }
    }
`;
