import { HTMLAttributes, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { UserType } from '/types/userTypes';
import Menu from '/front/types/frontMenuType';
import { MenuItem as MenuItemType } from './Menu.types';
import { colors } from '/css';
import { cmsMenusToLinks, getMenuForRole, getRoleMenus } from './Menu.utils';
import MenuItem from './Menu.Item';
import Divider from '/components/ui/Divider';
import Paragraph from '/components/ui/typography/Paragraph';
import Link from 'next/link';
import Icon from '/components/ui/Icon';
import { useRouter, NextRouter } from 'next/router';

const isActiveItem = (item: MenuItemType, router: NextRouter) =>
    item.activeOn
        ? item.activeOn.includes(router.pathname)
        : item.cms
        ? router.asPath === item.href
        : router.asPath.includes('/' + (item.href.split('/')[1] || '-'));

interface Props extends HTMLAttributes<HTMLDivElement> {
    user: UserType | undefined;
    menus: Menu[];
}

const NewMenu = ({ user, menus, ...props }: Props) => {
    const router = useRouter();
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const toggleMobileMenu = () => {
        document.getElementsByTagName('html')[0].classList.toggle('oh');
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const hideMobileMenu = () => {
        document.getElementsByTagName('html')[0].classList.remove('oh');
        setMobileMenuVisible(false);
    };

    useEffect(() => {
        if (document.getElementsByTagName('html')[0].classList.contains('oh')) {
            hideMobileMenu();
        }
    }, [router.asPath]);

    const roleMenus = getRoleMenus(user, menus);
    const dynamicMenu = getMenuForRole(roleMenus, router, user);

    return (
        <Component {...props}>
            <Nav
                id="nav"
                className="pl-48"
                mobileMenuVisible={mobileMenuVisible}
            >
                <div className="primary">
                    {dynamicMenu?.map((item) => (
                        <MenuItem
                            key={item.title}
                            item={item}
                            active={isActiveItem(item, router)}
                        />
                    ))}
                </div>
                <div className="secondary">
                    <Divider locked />
                    {cmsMenusToLinks('mobile-menu-secondary', menus).map(
                        (item, i) => (
                            <Paragraph
                                key={item.href}
                                size="large"
                                className="mb-24"
                            >
                                <Link prefetch={false} href={item.href}>
                                    {item.title}
                                </Link>
                            </Paragraph>
                        )
                    )}
                </div>
            </Nav>
            <Icon
                icon={mobileMenuVisible ? 'cross' : 'menu'}
                className="menu-icon ml-24"
                onClick={() => toggleMobileMenu()}
            />
        </Component>
    );
};

const Component = styled.div`
    @media (max-width: 940px) {
        order: 2;
    }
    .menu-icon {
        display: none;
        cursor: pointer;
        @media (max-width: 940px) {
            display: block;
        }
    }
`;

const Nav = styled.nav<{ mobileMenuVisible: boolean }>`
    div.primary {
        display: flex;
        align-items: center;
        position: relative;
        > div {
            margin-left: 24px;
            position: relative;
            a {
                font-family: 'gellix-semi';
                font-size: 17px;
                line-height: 22px;
                transition: 0.15s ease-in-out;
                white-space: nowrap;
                @media (pointer: fine) {
                    &:hover,
                    &:active {
                        color: ${colors.purple};
                    }

                    &:focus {
                        //color: ${colors.orange};
                        outline: none;
                    }
                }
            }
        }
    }

    .secondary {
        display: none;
        padding-right: 24px;
        a {
            color: ${colors.purple}!important;
        }
        .active {
            a {
                color: ${colors.purple};
            }
        }
    }

    @media (max-width: 940px) {
        position: fixed;
        display: none;
        top: 60px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 4px;
        padding-bottom: 20px;

        div.primary {
            padding-top: 24px;
            display: block;
            > div {
                margin: 0 0 12px;
                a,
                .dropdown-button {
                    font-size: 32px;
                    font-family: 'gellix-bold';
                    line-height: 38px;
                }
            }

            .dropdown-button {
                margin-bottom: 12px;
                display: none;
            }

            .dropdown-content {
                display: grid;
                position: relative;
                background-color: none;
                box-shadow: none;

                > div {
                    padding: 0;
                    margin: 0;
                    height: auto;

                    &:hover {
                        background-color: transparent !important;
                    }
                }
            }
        }

        .secondary {
            display: block;
        }

        ${(p) =>
            p.mobileMenuVisible &&
            css`
                display: block;
            `};
    }
`;

export default NewMenu;
