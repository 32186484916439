import { Component } from 'react';

import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';
import Button from './buttons/Button';
import Modal from '/components/ui/Modal';
import { isObject } from '/lib/helpers';

import dynamic from 'next/dynamic';
import HelpButton from '/components/ui/HelpButton';
const Slider = dynamic(() => import('/components/ui/Slider'), { ssr: false });

class Help extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    closeModal(e) {
        this.setState({
            isOpen: false,
        });
    }

    openModal() {
        this.setState({
            isOpen: true,
        });
    }

    openChat() {
        itxChatUtils.toggleChat(true);
        this.closeModal();
    }

    render() {
        let { title, text, images, children } = this.props;

        return (
            <>
                <HelpButton onClick={() => this.openModal()} />
                {this.state.isOpen && (
                    <Modal
                        closeRequestCallback={this.closeModal.bind(this)}
                        className="p-32 help-popup"
                    >
                        {title && (
                            <Heading small className="mb-24">
                                {title}
                            </Heading>
                        )}
                        {text && (
                            <Paragraph className="mb-32" weak html>
                                {text}
                            </Paragraph>
                        )}
                        {children}
                        {images && Array.isArray(images) && !!images.length && (
                            <Slider
                                images={images.map((img) => ({
                                    url: isObject(img) ? img.url : img,
                                }))}
                                className="mb-24"
                                id="help-images"
                                bulletAlign="flex-start"
                                autoplay={false}
                                background
                                height={'55.92%'}
                            />
                        )}
                        <Button
                            variant="secondary"
                            size="small"
                            onClick={() => this.closeModal()}
                        >
                            Ok, lukk
                        </Button>
                        <Button
                            variant="tertiary"
                            style={{ marginLeft: '12px' }}
                            size="small"
                            onClick={() => this.openChat()}
                        >
                            Chat med oss
                        </Button>
                    </Modal>
                )}
            </>
        );
    }
}

export default Help;
