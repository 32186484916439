import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { atom, useAtom } from 'jotai';
import config from '../config';

export const editModeAtom = atom(false);

const Importer = () => {
    const _ = (_: any) => null;

    const { canUseRichText } = config.useUser();
    const [editMode, setEditMode] = useAtom(editModeAtom);

    return useMemo(
        () => ({
            editMode,
            setEditMode,
            RichTextEditor: !canUseRichText
                ? _
                : dynamic(() => import('./RichTextEditor'), { ssr: false }),
            Actions: !canUseRichText
                ? _
                : dynamic(() => import('./FrontActions'), { ssr: false }),
            Button: !canUseRichText
                ? _
                : dynamic(() => import('./FrontButton'), { ssr: false }),
            Checkbox: !canUseRichText
                ? _
                : dynamic(() => import('./FrontCheckbox'), { ssr: false }),
            ConfirmButton: !canUseRichText
                ? _
                : dynamic(() => import('./FrontConfirmButton'), { ssr: false }),
            Editable: !canUseRichText
                ? _
                : dynamic(() => import('./FrontEditable'), { ssr: false }),
            Input: !canUseRichText
                ? _
                : dynamic(() => import('./FrontInput'), { ssr: false }),
            TagInput: !canUseRichText
                ? _
                : dynamic(() => import('./FrontTagInput'), { ssr: false }),
            ImageInput: !canUseRichText
                ? _
                : dynamic(() => import('./FrontImageInput'), { ssr: false }),
            Label: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLabel'), { ssr: false }),
            LinkPicker: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLinkPicker'), { ssr: false }),
            Loader: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLoadingCircle'), { ssr: false }),
            MediaBrowser: !canUseRichText
                ? _
                : dynamic(() => import('./MediaBrowser'), { ssr: false }),
            Modal: !canUseRichText
                ? _
                : dynamic(() => import('./FrontModal'), { ssr: false }),
            OverlayLock: !canUseRichText
                ? _
                : dynamic(() => import('./FrontOverlayLock'), { ssr: false }),
            Radio: !canUseRichText
                ? _
                : dynamic(() => import('./FrontRadio'), { ssr: false }),
            Select: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSelect'), { ssr: false }),
            Switch: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSwitch'), { ssr: false }),
            Tabs: !canUseRichText
                ? _
                : dynamic(() => import('./FrontTabs'), { ssr: false }),
            Bars: !canUseRichText
                ? _
                : dynamic(() => import('./FrontLoadingBars'), { ssr: false }),
            ColorPicker: !canUseRichText
                ? _
                : dynamic(() => import('./FrontColorPicker'), { ssr: false }),
            Slider: !canUseRichText
                ? _
                : dynamic(() => import('./FrontSlider'), { ssr: false }),
        }),
        [editMode, canUseRichText]
    );
};

export default Importer;
