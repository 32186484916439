import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Modal from '/components/ui/Modal';
import ModalHeader from '/components/ui/modal/ModalHeader';
import PhoneVerification from '/components/layout/PhoneVerification';
import Paragraph from '/components/ui/typography/Paragraph';
import { colors } from '/css';

interface Props extends HTMLAttributes<HTMLDivElement> {
    onClose: () => void;
    description?: string;
}

const LoginModal = ({ onClose, description, ...props }: Props) => {
    return (
        <Component closeRequestCallback={onClose} width="400px" {...props}>
            <div className="p-32">
                <ModalHeader>Logg inn</ModalHeader>
                {!!description && (
                    <Paragraph
                        color={colors.orange}
                        size="smaller"
                        className="mb-24"
                        centered
                    >
                        {description}
                    </Paragraph>
                )}

                <PhoneVerification
                    submitCodeButtonLabel="Bekreft kode"
                    forLogin
                    skipTermsCheck
                    storeUserOnSuccess
                    onSuccess={onClose}
                />
            </div>
        </Component>
    );
};

const Component = styled(Modal)`
    overflow: hidden;

    .phone-code-wrapper {
        .input-action-wrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .suffix {
                top: 15px;
                transform: none;
            }

            .input-error-message {
                position: relative;
            }
        }

        .code-message {
            width: 100%;
            margin-top: var(--distance-8);
        }

        .input-action-wrapper.phone {
            button#get-code {
                margin-top: var(--distance-24);
                width: 100%;
            }
        }
    }
`;

export default LoginModal;
