import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { atom, useAtom } from 'jotai';

import Container from '/components/Container';
import Menu from '/components/layout/menu/Menu';
import Logo from '/components/layout/Logo';
import { pathNameIs } from '/lib/helpers';
import { useUserState } from '/store/userStore';
import MenuType from '/front/types/frontMenuType';
import { useState } from 'react';
import PostCodeStatusButton from '/components/ui/PostCodeStatusButton';

const UserActions = dynamic(() => import('/components/ui/UserActions'), {
    ssr: false,
});

const CartButton = dynamic(() => import('/components/ui/CartButton'), {
    ssr: false,
});

interface Props {
    menus: MenuType[];
    simplified?: boolean;
}

export const headerThemeModeAtom = atom<'black' | 'white'>('black');

export default function Header({ menus, simplified = false }: Props) {
    const user = useUserState((state) => state.user);
    const [userActionsIsOpen, setUserActionsIsOpen] = useState(false);
    const [headerTheme] = useAtom(headerThemeModeAtom);

    const router = useRouter();
    const is = pathNameIs(router.pathname);
    const backgroundColor =
        is.backend || headerTheme === 'white' ? 'transparent' : '#fff';

    const handleUserActionsToggle = (isOpen: boolean) => {
        setUserActionsIsOpen(isOpen);
    };

    return (
        <Wrapper
            style={{ backgroundColor: backgroundColor }}
            className={`theme-${headerTheme}`}
            userActionsIsOpen={userActionsIsOpen}
        >
            <Container
                id="header"
                flex
                justify={simplified ? 'center' : 'space-between'}
                className="header-container"
                align="center"
            >
                <Logo />
                {!simplified && (
                    <div className="nav">
                        <Menu user={user} menus={menus} />
                        <div className="ml-24 flex align-center">
                            {!is.backend && (
                                <PostCodeStatusButton className="mr-12" />
                            )}
                            <UserActions
                                onUserActionsToggle={handleUserActionsToggle}
                            />
                            <CartButton />
                        </div>
                    </div>
                )}
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div<{ userActionsIsOpen: boolean }>`
    position: relative;
    @media (max-width: 940px) {
        z-index: 9998;
    }

    ${(p) =>
        p.userActionsIsOpen &&
        css`
            z-index: 999;
        `};

    .header-container {
        height: 64px;
        z-index: 101;
    }

    .nav {
        display: flex;
        align-items: center;
    }
    @media (min-width: 940px) {
        &.theme-white {
            .header-container {
                .logo .logo-text {
                    fill: #fff;
                }
                .nav a {
                    color: #fff;
                }
                .sign-in-button {
                }
                .user-actions {
                    .button-content span {
                        color: #fff;
                    }
                    .dropdown-content a {
                        color: #1f1a22;
                    }
                }
                .user-actions,
                .cart-button,
                .sign-in-button {
                    button {
                        border: 3px solid rgba(255, 255, 255, 0.2) !important;
                    }
                    svg {
                        path,
                        circle,
                        polygon {
                            fill: #fff;
                        }
                    }
                }
                .cart-button,
                .sign-in-button {
                    color: #fff;
                    border: 3px solid rgba(255, 255, 255, 0.2) !important;
                }
            }
        }
    }
`;
