import api from './api';

const contractorApi = {
    get: (uuid: string) => api.getSecure(`contractor/${uuid}`),
    getByArea: (postCode: string) =>
        api.getSecure(`contractor/by-area/${postCode}`),

    getAll: (token?: string) => api.getSecure('contractor', token),
    getAllWithCoverageDetails: (token?: string) =>
        api.getSecure('contractor?with_coverage_details=1', token),

    register: (data: any) => api.post(`auth/contractor/register`, data),

    assignDepartment: (contractorUuid: string, departmentId: number) =>
        api.post(`contractor/${contractorUuid}/department`, {
            department_id: departmentId,
        }),

    updateInvoiceEmails: (contractorUuid: string, emails: string[]) =>
        api.post(`contractor/${contractorUuid}/invoice-emails`, emails),

    addElectrician: (contractorUuid: string, newElectricianInfo: any) =>
        api.post(
            `contractor/${contractorUuid}/electrician`,
            newElectricianInfo
        ),

    getProjects: (contractorUuid: string, page: any) =>
        api.getSecure(`contractor/${contractorUuid}/project?page=${page}`),

    getProjectsForDays: ({
        contractorUuid,
        from,
        to,
        electricianUuid,
        dates,
    }: any) =>
        api.getSecure(
            `contractor/${contractorUuid}/project?${
                from && to
                    ? `from=${from}&to=${to}`
                    : dates.map((d: any) => `dates[]=${d}`).join('&')
            }${electricianUuid ? '&electrician_uuid=' + electricianUuid : ''}`
        ),

    getClaimsForDays: ({
        contractorUuid,
        from,
        to,
        electricianUuid,
        dates,
    }: any) =>
        api.getSecure(
            `contractor/${contractorUuid}/claim?${
                from && to
                    ? `from=${from}&to=${to}`
                    : dates.map((d: any) => `dates[]=${d}`).join('&')
            }${electricianUuid ? '&electrician_uuid=' + electricianUuid : ''}`
        ),

    getProjectsAffectedByGeneralAvailabilityChange: ({
        contractorUuid,
        electricianUuid,
        availability,
    }: any) =>
        api.post(
            `contractor/${contractorUuid}/electrician/${electricianUuid}/projects-affected-by-general-availability`,
            availability
        ),

    searchProjects: (contractorUuid: string, query: any) =>
        api.post(`contractor/${contractorUuid}/project/search`, { query }),

    getElectricians: (contractorUuid: string, token?: string) =>
        api.getSecure(`contractor/${contractorUuid}/electrician`, token),

    getElectrician: (
        contractorUuid: string,
        electricianUuid: string,
        token?: string
    ) =>
        api.getSecure(
            `contractor/${contractorUuid}/electrician/${electricianUuid}`,
            token
        ),

    sendFeedback: (contractorUuid: string, feedback: any) =>
        api.post(`contractor/${contractorUuid}/feedback`, { feedback }),

    getProjectSwapableElectricians: (
        contractorUuid: string,
        projectRef: string,
        token?: string
    ) =>
        api.getSecure(
            `contractor/${contractorUuid}/project/${projectRef}/swappable-electricians`,
            token
        ),

    swapProjectTimeslot: (
        contractorUuid: string,
        projectRef: string,
        timeslotUuid: string
    ) =>
        api.post(
            `contractor/${contractorUuid}/project/${projectRef}/swap-timeslot`,
            { timeslot_uuid: timeslotUuid }
        ),

    getClaimSwapableElectricians: (
        contractorUuid: string,
        claimRef: string,
        token?: string
    ) =>
        api.getSecure(
            `contractor/${contractorUuid}/claim/${claimRef}/swappable-electricians`,
            token
        ),

    swapClaimTimeslot: (
        contractorUuid: string,
        claimRef: string,
        timeslotUuid: string
    ) =>
        api.post(
            `contractor/${contractorUuid}/claim/${claimRef}/swap-timeslot`,
            { timeslot_uuid: timeslotUuid }
        ),

    updateContractorInfo: (contractorUuid: string, info: any) =>
        api.patch(`contractor/${contractorUuid}`, info),

    getPostCodeCoverageSuggestions: (
        contractorUuid: string,
        radius: any,
        postCode: string
    ) =>
        api.getSecure(
            `contractor/${contractorUuid}/post-codes?radius=${radius}${
                postCode ? '&post_code=' + postCode : ''
            }`
        ),

    deleteElectrician: (contractorUuid: string, electricianUuid: string) =>
        api.delete(
            `contractor/${contractorUuid}/electrician/${electricianUuid}`
        ),

    updateContractor: (contractorUuid: string, data: any) =>
        api.patch(`contractor/${contractorUuid}`, data),

    calendar: (
        contractorUuid: string,
        from: any,
        to: any,
        electricianUuid: string
    ) =>
        api.getSecure(
            `contractor/${contractorUuid}/calendar?from=${from}&to=${to}${
                electricianUuid ? '&electrician_uuid=' + electricianUuid : ''
            }`
        ),

    cancelProject: (contractorUuid: string, projectId: number, message: any) =>
        api.post(`contractor/${contractorUuid}/project/${projectId}/cancel`, {
            message,
        }),

    checkOrgNum: (number: string) => api.get(`brreg/${number}`),
};

export default contractorApi;
