import styled from 'styled-components';
import { ReactNode, HTMLAttributes } from 'react';

import { colors } from '/css';
import { headingSizes } from '/css/GlobalStyles';

enum HeadingSizes {
    XXL = 'xxl',
    XL = 'xl',
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

type HeadingSize = `${HeadingSizes}`;

export interface HeadingProps extends HTMLAttributes<HTMLElement> {
    size?: HeadingSize;
    children: ReactNode;
    centered?: boolean;
    light?: boolean;
    as?: any;
}

type SizeTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const sizeTags: Record<HeadingSizes, SizeTag> = {
    [HeadingSizes.XXL]: 'h1',
    [HeadingSizes.XL]: 'h2',
    [HeadingSizes.LARGE]: 'h3',
    [HeadingSizes.MEDIUM]: 'h4',
    [HeadingSizes.SMALL]: 'h5',
};

const Heading = ({
    children,
    size = HeadingSizes.XXL,
    light,
    centered,
    as,
    ...rest
}: HeadingProps) => {
    return (
        <Component
            as={as || sizeTags[size]}
            size={size}
            light={light}
            centered={centered}
            {...rest}
        >
            {children}
        </Component>
    );
};

export default Heading;

const Component = styled.h1<{
    size: HeadingSize;
    light?: boolean;
    centered?: boolean;
    color?: string;
}>`
    font-family: ${(p) => (p.light ? `gellix-semi` : `gellix-bold`)};
    color: ${(p) => p.color || colors.black};
    position: relative;
    font-size: ${(p) =>
        headingSizes.find((a) => a.name === p.size)?.desktop.fs}px;
    line-height: ${(p) =>
        headingSizes.find((a) => a.name === p.size)?.desktop.lh}px;
    text-align: ${(p) => (p.centered ? 'center' : 'left')};
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: ${(p) =>
            headingSizes.find((a) => a.name === p.size)?.tablet.fs}px;
        line-height: ${(p) =>
            headingSizes.find((a) => a.name === p.size)?.tablet.lh}px;
    }
    @media (max-width: 767px) {
        font-size: ${(p) =>
            headingSizes.find((a) => a.name === p.size)?.mobile.fs}px;
        line-height: ${(p) =>
            headingSizes.find((a) => a.name === p.size)?.mobile.lh}px;
    }
`;
