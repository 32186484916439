import { on } from 'ramda';
import { css } from 'styled-components';
import {
    aboveMobile,
    belowDesktop,
    belowTabile,
    belowTablet,
    mobile,
    tabile,
    tablet,
} from './breakpoints';

const responsiveHelpers = css`
    .show-on-desktop {
        ${belowDesktop} {
            display: none !important;
        }
    }

    .show-on-mobile {
        display: none !important;

        ${mobile} {
            display: revert !important;
        }
    }

    .show-on-tablet {
        display: none !important;
        ${tablet} {
            display: revert !important;
        }
        ${belowTablet} {
            display: none !important;
        }
    }

    .show-on-tablet-and-below,
    .show-below-desktop {
        display: none !important;

        ${tablet} {
            display: revert !important;
        }
    }

    .show-on-tabile {
        display: none !important;
        ${tabile} {
            display: revert !important;
        }
        ${belowTabile} {
            display: none !important;
        }
    }

    .show-on-tabile-and-below {
        display: none !important;

        ${tabile} {
            display: revert !important;
        }
    }

    .hide-on-desktop {
        display: none !important;
        ${belowDesktop} {
            display: revert !important;
        }
    }

    .hide-below-desktop {
        ${tablet} {
            display: none !important;
        }
    }

    .hide-below-tablet {
        ${tabile} {
            display: none !important;
        }
    }

    .hide-below-tabile {
        ${mobile} {
            display: none !important;
        }
    }

    .hide-on-desktop {
        display: none !important;
        ${belowDesktop} {
            display: revert !important;
        }
    }

    .hide-on-tablet {
        ${tablet} {
            display: none !important;
        }
        ${belowTablet} {
            display: revert !important;
        }
    }

    .hide-on-tabile {
        ${tabile} {
            display: none !important;
        }
        ${belowTabile} {
            display: revert !important;
        }
    }

    .hide-on-mobile {
        ${mobile} {
            display: none !important;
        }
    }
`;

export default responsiveHelpers;
