import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { colors } from '/css';
import buttonSizes from './buttonSizes';
import { ReactNode } from 'react';
import buttonVariants from './buttonVariants';
import LoadingCircle from '../LoadingCircle';
import spreadPropsClassName from '/lib/utils/spreadPropsClassName';

export interface ButtonStyleProps extends ButtonProps {
    manuallyDisabled?: boolean;
    hasChildren: boolean;
}

interface BaseButtonProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    style?: CSSProperties;
    icon?: ReactNode;
    link?: string | { href: string; target?: '_blank' };
    disabled?: boolean;
    fullWidth?: boolean;
    raised?: boolean;
    busy?: boolean;
    iconPosition?: 'left' | 'right';
    size?: 'large' | 'medium' | 'small';
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'soft'
        | 'orange'
        | 'black'
        | 'grey';
    onClick?: (e: any) => void;
}

export interface ButtonProps extends BaseButtonProps {
    children: React.ReactNode;
}

export interface IconButtonProps extends BaseButtonProps {
    children?: never;
    icon: ReactNode;
    iconPosition?: never;
}

interface LinkButtonProps extends ButtonProps {
    link: ButtonProps['link'];
    onClick?: never;
}

interface ClickButtonProps extends ButtonProps {
    link?: never;
    onClick: ButtonProps['onClick'];
}

export type AllButtonProps =
    | ButtonProps
    | IconButtonProps
    | LinkButtonProps
    | ClickButtonProps;

const Button = ({
    children,
    icon,
    iconPosition = 'right',
    onClick,
    size = 'large',
    link,
    ...props
}: AllButtonProps) => {
    const getLinkProps = () => {
        if (!link) return {};

        return {
            ...(typeof link === 'string'
                ? { href: props.disabled ? '#' : link, as: 'a' }
                : { ...link, as: 'a' }),
        };
    };

    const iconElement = icon ? <div className="button-icon">{icon}</div> : null;

    return (
        <Component
            size={size}
            disabled={props.disabled || props.busy}
            manuallyDisabled={props.disabled}
            iconPosition={iconPosition}
            hasChildren={!!children}
            onClick={
                props.disabled || props.busy || !!link ? undefined : onClick
            }
            {...getLinkProps()}
            {...spreadPropsClassName(
                props,
                props.disabled ? 'disabled' : undefined
            )}
        >
            <div className="button-content">
                {iconPosition === 'left' && iconElement}
                {children}
                {iconPosition === 'right' && iconElement}
            </div>
            {props.busy && (
                <div className="loading-wrapper">
                    <LoadingCircle
                        color={
                            props.variant === 'tertiary'
                                ? colors.black
                                : colors.white
                        }
                    />
                </div>
            )}
        </Component>
    );
};

const Component = styled.button<ButtonStyleProps>`
    cursor: pointer;
    display: ${(p) => (p.fullWidth ? 'flex' : 'inline-flex')};
    outline: none;
    border-radius: 12px;
    font-family: var(--font-semibold);
    text-align: center;
    align-items: center;
    justify-content: center;
    width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
    //border: 3px solid transparent;

    .button-icon {
        position: relative;
        top: 1px;

        svg {
            transition: color 0.2s ease-in-out;
        }

        ${(p) =>
            !p.hasChildren &&
            css`
                margin-right: 0;
                margin-left: 0;
            `}
    }

    ${(p) => buttonSizes[p.size || 'large'](p)}
    ${(p) => buttonVariants[p.variant || 'primary'](p)}

    ${(p) =>
        p.manuallyDisabled &&
        css`
            background-color: rgba(31, 26, 34, 0.04);
            color: ${colors.grey} !important;
            cursor: default !important;

            @media (pointer: fine) {
                &:hover,
                &:focus {
                    background-color: rgba(31, 26, 34, 0.04);
                }
            }

            .button-icon svg {
                color: ${colors.grey};
                path,
                polygon,
                rect {
                    fill: ${colors.grey}!important;
                }
            }
        `}

    .loading-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    ${(p) =>
        p.iconPosition === 'left' &&
        css`
            .button-icon {
                margin-right: 4px;
            }
        `}

    ${(p) =>
        p.iconPosition === 'right' &&
        css`
            .button-icon {
                margin-left: 4px;
            }
        `}

    

    .button-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    ${(p) =>
        p.busy &&
        css`
            .button-content {
                opacity: 0;
            }
        `};

    user-select: none;

    ${(p) =>
        p.disabled &&
        css`
            pointer-events: none;
        `};
`;

export default Button;
