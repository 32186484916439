import ConfiguredRoof from '/modules/solar/types/ConfiguredRoofType';
import { ReactNode } from 'react';
import Image from '/types/imageType';
import { TotalPriceType } from '/types/priceType';

export enum JobDestinations {
    PROJECT = 'project',
    OFFER = 'offer',
    CART = 'cart',
}
export interface Answer {
    questionId: string;
    questionType: QuestionType | RoofQuestionType;
    answerId: string | string[];
    answerValue: number | string | string[] | Image[];
    productId?: number | number[];
}

export type Answers = Answer[];

export type RoofAnswers = {
    selectedRoofs: ConfiguredRoof[];
};

export type Material = {
    articleDescriptions: {
        description: string;
    };
    articleNo: string;
    brandName: string;
    ean: string;
    friendlyName: string;
    name: string;
    optional: boolean;
    picture: {
        url?: string;
        uRL?: string;
    }[];
    price: {
        spotonPrice?: string;
        customerPrice: number;
        discountGroup: number;
        discountGroup2: number;
        price: number;
        salesPacking: number;
        salesPackingUnit: string;
        unit: string;
        vat: number;
    };
    quantity: number;
    ranking: number;
    soldBy: string;
    isProduct?: boolean;
};

export interface Choice {
    id: string;
    description: string;
    effects: Effect[];
    label: string;
    value: string;
    disabled?: boolean;
    imageUrl?: string;
    imageAlt?: string;
    productPage?: number;
    solarPanelWattage?: number;
    stockInfo?: ReactNode;
    stock?: number;
}

export interface Effect {
    property:
        | ''
        | 'totalPrice'
        | 'materialList'
        | 'optionalQuestion'
        | 'workHours';
    operator:
        | ''
        | 'add'
        | 'subtract'
        | 'addPercent'
        | 'subtractPercent'
        | 'multiply';
    value: string | number;
    collapsed: boolean;
    conditonalOperator: '' | '=' | '<' | '>'; // used in effects on number questions sonly
    conditonalValue: number; // used in effects on number questions sonly
    subtractQuestionDefaultValueFromAnswerValue: boolean; // used in effects on number questions sonly
    materialListEffectedMaterial: string | Material; // used for material list effects only
    materialListEffectType:
        | ''
        | 'increaseQuantity'
        | 'decreaseQuantity'
        | 'setQuantity'
        | 'multiplyQuantity'
        | 'setSpotonPrice'
        | 'quantityPriceMap'
        | 'addMaterial'
        | 'removeMaterial'; // used for material list effects only
    materialListEffectQuantity: number; // used for material list effects only
    materialListEffectNumberQuestion: string; // question id used for material list effects only
    materialListQuantityEffectSource: 'fixedNumber' | 'numericQuestion';
    materialListEffectNumberQuestionAnswerModification:
        | 'none'
        | 'add'
        | 'subtract'
        | 'multiply'
        | 'divide';
    materialListEffectNumberQuestionAnswerModificationNumber: number;
    effectHasAdvancedConditions: boolean;
    effectAdvancedConditions:
        | null
        | {
              questionId: string;
              answerId: string;
          }[][];
    effectHasSolarConditions?: boolean;
    effectSolarConditions?: null | {
        panelOrientation: '' | 'horisontal' | 'vertical';
    };
}

export enum QuestionType {
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    FILE_UPLOAD = 'file upload',
    NUMBER = 'number',
}

export enum RoofQuestionType {
    PANEL = 'panelType',
    ROOF = 'roofType',
    PANEL_QUANTITY = 'panelQty',
}

export interface Question {
    type: QuestionType;
    id: string;
    title: string;
    description: string;
    defaultValue: string | string[] | number;
    choices: Choice[];
    effects: Effect[];
    helpImages: any[];
    helpText: string;
    fileLimit?: number;
    maxValue?: number;
    minValue?: number;
    optional?: boolean;
    answerLimit?: number;
    theme?: 'slider' | 'ticker';
    usedForRoofConfiguration?: boolean;
    usedForTotalWattageEffects?: boolean;
    usedForBuildingWattageEffects?: boolean;
    usedForPerBuildingEffects?: boolean;
    usedForPerRoofAreaEffects?: boolean;
    roofQuestionType?: RoofQuestionType;
    label?: string;
    visible: boolean;
    reveals: string[];
}

export interface ChoiceQuestionType extends Question {
    type: QuestionType.RADIO | QuestionType.CHECKBOX;
}

interface Seo {
    description?: string;
    image?: string;
    keywords?: string;
    title?: string;
}

interface JobInfo {
    additionalElectricianInfo?: any; //rich text
    category?: { name: string; value: string }[];
    description?: string;
    descriptionHeading?: string;
    disabledWhenUpselling?: boolean;
    images?: { url: string }[];
    importantNote?: string;
    popular?: boolean;
    seo?: Seo;
    tips?: string;
    title?: string;
}

export interface JobTypeVersion {
    id: number;
    defaultMaterials: Material[]; // default materials set in "materials" tab of configurator
    potentialMaterials: Material[]; // All materials that can potentially be used in this job (from all effects)
    materialsIncludedOnReceipt: string[];
    name: string;
    questions: Question[];
    slug: string;
    workHours: 0; // starting work hours value saved on job templ. version
    info: JobInfo;
    additionalCost: number; // ???
    categories: any[];
    image: string;
    notes: string;
    settings: {
        disablePrepayments?: boolean;
    };
}

export interface TemplateVersion {
    created_at: string;
    created_by: string;
    id: number;
    isActiveVersion: boolean;
    notes: string;
    released: boolean;
    releasedAt: string;
}

interface Template {
    jobTypeId: number;
    jobTypeVersionId: number;
    materials: Material[];
    materialsEdited: boolean;
    editedMaterialList: Material[] | null;
    expiredMaterials: Material[] | null;
    vismaArticleNumber: string | null;
    workHours: number;
    totals: TotalPriceType;
    versions: TemplateVersion[];
    answers: Answers;
}

export enum JobTemplateType {
    ELECTRICIAN = 'ELECTRICIAN',
    SOLAR = 'SOLAR',
    HEAT_PUMP = 'HEAT_PUMP',
}

export interface JobTemplate extends Template {
    typeKey: JobTemplateType;
    published: boolean;
    order: number;
    jobTypeVersion: JobTypeVersion;
    images: Image[];
    commentForElectrician: string | null;
    //"revision_required": false,
    //"cancel_reason": null,
    //"cancelled": false,
    //"roof_answers": null,
    //"meta_data": null,
}

interface SolarJobInfo {
    importantNote?: string | null;
    seo?: Seo;
}

interface SolarJobTypeVersion extends Omit<JobTypeVersion, 'info'> {
    info: SolarJobInfo;
}

export interface SolarJobTemplate extends Template {
    typeKey: 'SOLAR';
    jobTypeVersion: SolarJobTypeVersion;
}

export interface CmsCompatibilityStatus {
    addedProducts: number[];
    removedProducts: number[];
    pagesWithJobModule: Array<{
        id: number;
        path: string;
        slug: string;
        title: string;
        _type: string;
    }>;
}
