import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useInitialState } from './store.helpers';

interface OnboardingCompany {
    organizationNumber: string;
    name: string;
    address: string;
    partnerId: string;
    postArea: string;
    postCode: string;
}

interface OnboardingStoreState {
    step: number;
    company: OnboardingCompany;
}

const initialState: OnboardingStoreState = {
    step: 1,
    company: {
        organizationNumber: '',
        name: '',
        address: '',
        partnerId: '',
        postArea: '',
        postCode: '',
    },
};

interface OnboardingStateMutators {
    resetOnboardingState: () => void;
    setOnboardingStep: (step: number) => void;
    setContractorCompany: (company: OnboardingCompany) => void;
}

interface OnboardingStore
    extends OnboardingStoreState,
        OnboardingStateMutators {}

export const usePersistedOnboardingState = create<OnboardingStore>()(
    persist(
        (set) => ({
            ...initialState,
            resetOnboardingState: () => set({ ...initialState }),
            setOnboardingStep: (step: number) => set({ step }),
            setContractorCompany: (company: OnboardingCompany) =>
                set({ company }),
        }),
        {
            name: 'onboarding',
        }
    )
);

export const useOnboardingState = <T>(
    selector: (state: OnboardingStore) => T
): T =>
    useInitialState<OnboardingStore, OnboardingStoreState>(
        usePersistedOnboardingState,
        initialState,
        selector
    );
