import { useState } from 'react';

import { ui, config, Row, Col } from './../';
import ButtonType from '../types/frontButtonType';
import Link from '../types/frontLinkType';

interface Props
    extends React.HTMLAttributes<HTMLButtonElement | HTMLDivElement> {
    value: ButtonType | null;
    onChange: (value: any) => void;
    onDelete?: (value: any) => void;
    children: any;
    linkPickerProps?: any;
    [key: string]: any;
}

export default function Button({
    value,
    onChange,
    onDelete,
    children,
    linkPickerProps = {},
    ...rest
}: Readonly<Props>) {
    const [linkPickerVisible, setLinkPickerVisible] = useState(false);
    const [buttonType, setButtonType] = useState(
        value?.buttonType || 'primary'
    );
    const [buttonSize, setButtonSize] = useState(
        value?.buttonSize || 'default'
    );

    const { editMode, Actions, LinkPicker, Select, Label } = ui();

    const ButtonComponent =
        config.presentation?.Button?.buttonComponent || 'button';

    const buttonPresentationProps = {
        variant: value?.buttonType || 'primary',
        link: value?.url
            ? { href: value?.url, target: value?.target || '_self' }
            : undefined,
        size:
            !value?.buttonSize || value?.buttonSize === 'default'
                ? 'large'
                : value?.buttonSize,
    };

    if (!editMode)
        return (
            <ButtonComponent {...rest} {...buttonPresentationProps}>
                {children}
            </ButtonComponent>
        );

    const onLink = (linkData: Link) => {
        onChange({ ...linkData, buttonType, buttonSize });
        setLinkPickerVisible(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            <ButtonComponent {...rest} {...buttonPresentationProps}>
                {children}
            </ButtonComponent>
            <Actions
                align="center"
                onEdit={() => setLinkPickerVisible(true)}
                onDelete={onDelete ? () => onDelete(value) : undefined}
            />
            {linkPickerVisible && (
                <LinkPicker
                    title={value?.title || ''}
                    url={value?.url || ''}
                    target={value?.target || '_self'}
                    type={value?.type || ''}
                    id={value?.id || ''}
                    slug={value?.slug || ''}
                    onClose={() => setLinkPickerVisible(false)}
                    onLink={onLink}
                    serchableTypes={['page', 'job']}
                    {...linkPickerProps}
                >
                    <Row className="ft-mb-24">
                        <Col width={6}>
                            <Label
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '8px',
                                }}
                            >
                                Button type
                            </Label>
                            <Select
                                value={buttonType}
                                options={['primary', 'secondary', 'tertiary']}
                                onChange={setButtonType}
                                medium
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col width={6}>
                            <Label
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom: '8px',
                                }}
                            >
                                Button size
                            </Label>
                            <Select
                                value={buttonSize}
                                options={['default', 'medium', 'small']}
                                onChange={setButtonSize}
                                medium
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </LinkPicker>
            )}
        </div>
    );
}
