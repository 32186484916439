import styled, { css } from 'styled-components';
import { ReactNode, ElementType, HTMLAttributes } from 'react';

import { colors } from '/css';
import { paragraphSizes } from '/css/GlobalStyles';

enum Sizes {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    SMALLER = 'smaller',
    NANO = 'nano',
    SMALLEST = 'smallest',
}

type Size = `${Sizes}`;

enum Weights {
    LIGHT = 'light',
    WEAK = 'weak',
    STRONG = 'strong',
}

type Weight = `${Weights}`;

export interface ParagraphProps extends HTMLAttributes<HTMLElement> {
    children: ReactNode;
    weight?: Weight;
    size?: Size;
    html?: boolean; // renders children string as HTML
    centered?: boolean; // max-width with hor. auto margins
    maxWidth?: string; // in px
    color?: string;
}

const Paragraph = ({
    children,
    html,
    size = Sizes.SMALL,
    weight,
    centered,
    maxWidth,
    color,
    ...rest
}: ParagraphProps) => {
    return html ? (
        <Component
            {...rest}
            size={size}
            weight={weight}
            centered={centered}
            maxWidth={maxWidth}
            color={color}
            //@ts-ignore
            dangerouslySetInnerHTML={{ __html: children }}
        />
    ) : (
        <Component
            size={size}
            weight={weight}
            centered={centered}
            maxWidth={maxWidth}
            color={color}
            {...rest}
        >
            {children}
        </Component>
    );
};

export default Paragraph;

interface StyledProps {
    size: Size;
    weight?: Weight;
    centered?: boolean;
    maxWidth?: string;
    color?: string;
}

const Component = styled.p<StyledProps>`
    color: ${(p) => p.color || colors.black};
    position: relative;
    font-family: var(--font-semibold);
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
    word-break: break-word;

    ${({ maxWidth }) =>
        maxWidth
            ? `
        max-width: ${maxWidth};
        margin-left: auto;
        margin-right: auto;
    `
            : ``}

    ${({ size }) => {
        const styles = paragraphSizes.filter((s) => s.name === size)[0];

        if (!styles) return ``;

        return css`
            font-size: ${styles.desktop.fs}px;
            line-height: ${styles.desktop.lh}px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: ${styles.tablet.fs}px;
                line-height: ${styles.tablet.lh}px;
            }
            @media (max-width: 767px) {
                font-size: ${styles.mobile.fs}px;
                line-height: ${styles.mobile.lh}px;
            }
        `;
    }}

    ${(p) =>
        p.weight === Weights.LIGHT &&
        css`
            font-family: var(--font-regular);
        `}

    ${(p) =>
        p.weight === Weights.STRONG &&
        css`
            font-family: var(--font-bold);
        `}

    ${(p) =>
        p.weight === Weights.WEAK &&
        css`
            font-family: var(--font-regular);
            color: ${colors.grey};
        `}
`;
