import { useEffect } from 'react';
import Router from 'next/router';
import styled from 'styled-components';

import Layout from '/components/Layout';
import HeadMeta from '/components/HeadMeta';

import { cookie, userIs } from '/lib/helpers';
import Help from '/components/ui/Help';
import Container from '/components/Container';
import Heading from '/components/ui/Heading';
import Description from '/components/ui/typography/Description';
import PhoneVerification from '/components/layout/PhoneVerification';
import withMenus from '/components/hoc/withMenus';
import { useHydration } from '/store/store.helpers';
import { useUserState } from '/store/userStore';
import Menu from '/front/types/frontMenuType';
import { UserRole, UserType } from '/types/userTypes';
import { useOrderState } from '/store/orderStore';

export const redirectToUserLandingPage = (
    user: UserType,
    redirectFunction: (path: string) => void
) => {
    if (!user) return;
    if (userIs(user, UserRole.CUSTOMER)) return redirectFunction('/dashboard');
    if (userIs(user, UserRole.SALES_PARTNER_SELLER))
        return redirectFunction('/selger');
    if (userIs(user, UserRole.STORE_EMPLOYEE))
        return redirectFunction('/prosjekter');
    if (userIs(user, UserRole.ELECTRICIAN))
        return redirectFunction(`/montor/${user?.uuid}/kalender`);
    if (userIs(user, UserRole.CONTRACTOR_ADMIN))
        return redirectFunction(`/partner/${user?.contractor?.uuid}/kalender`);
    if (userIs(user, UserRole.CASEWORKER))
        return redirectFunction('/prosjekter');
    redirectFunction('/prosjekter');
};

interface Props {
    menus: Menu[];
}

const Auth = ({ menus }: Props) => {
    const user = useUserState((state) => state.user);
    const clearUser = useUserState((state) => state.clearUser);
    const hydrated = useHydration();

    const clearOrderCart = useOrderState((state) => state.clearOrderCart);
    const clearOrderContactInformation = useOrderState(
        (state) => state.clearOrderContactInformation
    );

    useEffect(() => {
        if (!hydrated) return;
        if (!user || !cookie.get('AuthToken')) {
            cookie.set('AuthToken', '', 0);
            cookie.set('ContractorUuid', '', 0);
            clearUser();
        } else {
            redirectToUserLandingPage(user, Router.replace);
        }
    }, [hydrated]);

    const onSignInSuccess = (user: UserType) => {
        if (userIs(user, UserRole.SALES_PARTNER_SELLER)) {
            clearOrderCart();
            clearOrderContactInformation();
        }

        if (Router.query.redirect) {
            // @ts-ignore
            return Router.replace(Router.query.redirect);
        }

        redirectToUserLandingPage(user, Router.replace);
    };

    return (
        <Layout menus={menus || []}>
            <HeadMeta
                title="Logg inn"
                links={[
                    {
                        rel: 'canonical',
                        href: process.env.NEXT_PUBLIC_APP_URL + '/logginn',
                    },
                ]}
            />

            <Wrapper className="pt-120 pb-120">
                <Container small className="container">
                    <Heading large className="mb-24 heading" tag="div">
                        Logg inn
                    </Heading>
                    <Description className="mb-48 flex justify-space-between">
                        <span
                            style={{ maxWidth: '386px', paddingRight: '16px' }}
                        >
                            Her kan man logge inn og se status på bestilte
                            prosjekter og befaringer
                        </span>
                        <Help
                            title="Logg inn"
                            text="Du trenger ikke logge inn for å bestille av oss. Når du har gjort en bestilling så vil du få en egen “min side” du kan logge inn på for å se en oversikt over dine bestillinger og status på disse."
                        />
                    </Description>
                    <PhoneVerification
                        submitCodeButtonLabel="Bekreft kode"
                        forLogin
                        skipTermsCheck
                        className="mb-32"
                        onSuccess={onSignInSuccess}
                        storeUserOnSuccess
                    />
                </Container>
            </Wrapper>
        </Layout>
    );
};

export default withMenus(Auth);

const Wrapper = styled.div`
    .container {
        max-width: 500px;
    }
    .checkmark {
        width: 60px;
        height: 60px;
        @media (max-width: 767px) {
            width: 40px;
            height: 40px;
        }
    }
`;
